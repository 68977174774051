// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("src/assets/images/jsc/bottom.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/jsc/btn_select.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".normal-layout{position:relative;width:100%;left:0;top:0;padding-bottom:1.75rem;background:linear-gradient(90deg,rgba(2,5,16,.77) 13%,rgba(2,5,16,0) 31%,transparent 51%,rgba(2,5,16,0) 71%,rgba(2,5,16,.77) 90%);opacity:1;pointer-events:none}.normal-layout .touch-enable{pointer-events:auto}.normal-layout .bottom_{left:50%;bottom:0;transform:translate3d(-50%,0,0);height:2.41667rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.i_bg,.normal-layout .bottom_{position:absolute;width:100%;background-size:100% 100%;z-index:1}.i_bg{height:100%}.router_go{position:absolute;bottom:1.25rem;width:100%;display:flex;align-items:center;justify-content:center}.router_go .tab_name{color:#fff;text-align:center;background:linear-gradient(90deg,#fff,#8be3da);-webkit-background-clip:text;-webkit-text-fill-color:transparent}.router_go .active,.router_go .tab_name{width:5.20833rem;height:1.625rem;font-family:PingFangSC,PingFang SC;cursor:pointer;pointer-events:auto;font-weight:600;font-size:.83333rem;line-height:1.16667rem;font-style:normal}.router_go .active{color:#ffec00;text-align:center;background:linear-gradient(90deg,#fff,#ffec00);background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-size:100% 100%}.router_go .margin-r-106{margin-right:4.41667rem}.router_go:last-child{margin-right:0}", ""]);
// Exports
module.exports = exports;
