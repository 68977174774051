<template>
  <div class="header touch-enable">
    <i class="header-content-bg"></i>
    <div class="times_s">
      <span>{{ currentDate }}</span>
       <span class="margin-l-10">{{ currentDay }}</span>
      <span class="margin-l-10">{{ currentTime }}</span>
     
    </div>
    <div class="header-title-text">{{ commonHeadTitle }}</div>
    <div class="scha" @click="quanping">筛选</div>
    <slot />
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentDate:"",
      currentTime: "",
      currentDay: "",
    };
  },
  components: {},
  computed: {
    commonHeadTitle() {
      return "繁阳稻虾综合种养数字农田驾驶舱";
    },
  },
  mounted() {
    this.updateTime();
    // 每秒更新一次时间
    setInterval(this.updateTime(), 1000);
  },
  methods: {
  updateTime() {
    const now = new Date();
        // 格式化年月日
      this.currentDate = this.formatDate(now)

    // 格式化时分秒
    this.currentTime = this.formatTime(now);

    // 获取星期几
    this.currentDay = this.getDay(now);
  },
    formatDate(date) {
      const year = date.getFullYear()
      const month = this.padZero(date.getMonth() + 1)
      const day = this.padZero(date.getDate())
      return `${year}年${month}月${day}日`
    },
  formatTime(date) {
    const hours = this.padZero(date.getHours());
    const minutes = this.padZero(date.getMinutes());
    const seconds = this.padZero(date.getSeconds());
    return `${hours}:${minutes}:${seconds}`;
  },
  padZero(num) {
    return num.toString().padStart(2, "0");
  },
  getDay(date) {
    const days = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
    return days[date.getDay()];
  },
  quanping(){
    console.log('全屏')
      const elem = document.documentElement;
      if (!document.fullscreenElement) {
        // Try to enter full-screen mode
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
          // Firefox
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
          // Chrome, Safari and Opera
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
          // IE/Edge
          elem.msRequestFullscreen();
        }
      } else {
        // Try to exit full-screen mode
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          // Firefox
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          // Chrome, Safari and Opera
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          // IE/Edge
          document.msExitFullscreen();
        }
      }

  },
},
};
</script>
<style lang="scss">
.header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: px2vh(87);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 px2vw(86) 0 px2vw(78);
  box-sizing: border-box;
}
.times_s {
  display: flex;
  align-items: center;
  font-size: px2vh(22);
  color: #FFFFFF;
    font-family: "ysbth";
  font-style: normal;
  line-height: px2vh(29);
  padding-top: px2vh(20);
  box-sizing: border-box;
}
.header-content-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  height: px2vh(87);
  background-image: url("~src/assets/images/jsc/top_border.png");
  background-size: 100% 100%;
}

.header-title-text {
  position: absolute;
   left: 50%;
  transform: translate3d(-50%, -50%, 0);
   margin-top: px2vh(60);
  z-index: 2;

  font-size: px2vh(42);
  font-weight: 400;
  color: #ffffff;
  line-height: px2vh(55);
  letter-spacing: px2vh(4);
  font-family: "ysbth";
  text-shadow: 0px 0px px2vh(10) rgba(0, 182, 205, 0.8);
}
@keyframes pulsate-fwd {
  0% {
    transform: scaleX(1);
  }
  30% {
    transform: scale3d(0.8, 1.25, 1);
    opacity: 1;
  }
  40% {
    transform: scaleY(0.75);
    opacity: 0.5;
  }
  50% {
    transform: scale3d(0.2, 1.15, 1);
    opacity: 0;
  }
  65% {
    transform: scaleY(0.95);
    opacity: 0.5;
  }
  75% {
    transform: scale3d(0.8, 1.05, 1);
    opacity: 1;
  }
  100% {
    transform: scaleX(1);
  }
}
@keyframes pulsates {
  0% {
    left: 34%;
  }
  100% {
    left: 48%;
  }
}
.margin-l-10 {
  margin-left: px2vw(10);
}
.scha {
  z-index: 99;
}
</style>
