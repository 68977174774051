// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/component_img/btn_bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".title[data-v-4198b88e]{position:relative;width:17.375rem;height:1.75rem;box-sizing:border-box;align-items:center;justify-content:space-between}.title[data-v-4198b88e],.title .title-box[data-v-4198b88e]{display:flex}.title .title-box .itim_is[data-v-4198b88e]{width:.66667rem;height:1.25rem}.title .title-box .title-text[data-v-4198b88e]{font-size:1rem;line-height:1.29167rem;color:#fff;font-family:ysbth;font-weight:600;margin-left:.25rem}.title .btn_cont[data-v-4198b88e]{width:4rem;height:1.33333rem;display:flex;align-items:center;justify-content:center;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%;color:#8efffc;font-size:.66667rem;font-family:ysbth}.title .border_bottoms[data-v-4198b88e]{position:absolute;left:0;bottom:0;width:17.375rem;height:.16667rem}", ""]);
// Exports
module.exports = exports;
