<template>
  <div class="left-group">
    <slot />
  </div>
</template>

<script>
export default {}
</script>
<style lang="scss">
.left-group {
  position: relative;
  z-index:5;
  margin-left: px2vw(26);
  margin-top: px2vh(87);
  width: px2vw(466);
  height: px2vh(925);
  padding: px2vh(10) px2vw(24);
  box-sizing: border-box;
 background: rgba(4,26,29,0.67);
  background-size: 100% 100%;
  pointer-events: auto;
}
</style>
