import request from '@/utils/request'
//登录
export function login_Api(params) {
    return request({
        url: '/dataCenter/doLogin',
        method: 'get',
        params,
    })
}
//获取配置信息
export function getConfig_Api(params) {
    return request({
        url: '/dataCenter/configList' + params,
        method: 'get',
    })
}

//基地简介
export function getBaseInfo_Api(params) {
    return request({
        url: '/bigScreen/v1/getBaseDetail',
        method: 'get',
    })
}
//基地管理
export function getBaseManage_Api(params) {
    return request({
        url: '/bigScreen/v1/getBaseUserList',
        method: 'get',
    })
}
//养殖模式
export function getUnionExpert_Api(params) {
    return request({
        url: '/bigScreen/v1/getBreedMode',
        method: 'get',
    })
}
//云上同福
export function getCloudFriend_Api(params) {
    return request({
        url: '/bigScreen/v1/getVrList',
        method: 'get',
    })
}
//联合专家
export function getUnionExpert_Api1(params) {
    return request({
        url: '/bigScreen/v1/getExpertList',
        method: 'get',
    })
}
//分区面积
export function getArea_Api(params) {
    return request({
        url: '/bigScreen/v1/getLandPartitionAreaList',
        method: 'get',
    })
}
//种养面积
export function getLand_Api(params) {
    return request({
        url: '/bigScreen/v1/getBreedAreaList',
        method: 'get',
    })
}
//数据统计
export function getData_Api(params) {
    return request({
        url: '/bigScreen/v1/getStatisticsData',
        method: 'get',
    })
}