import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/ntgl",
  },

  {
    path: '/ntgl', //农田管理
    name: 'ntgl',
    component: () =>
      import("../views/ntgl/index.vue"),
  },
  {
    path: '/wlsb', //物联设备
    name: 'wlsb',
    component: () =>
      import("../views/wlsb/index.vue"),
  },
  {
    path: '/zhgg', //智慧灌溉
    name: 'zhgg',
    component: () =>
      import("../views/zhgg/index.vue"),
  },

  {
    path: '/login', //登录
    name: 'login',
    component: () =>
      import("../views/login/index.vue"),
  }
];


const router = new VueRouter({
  routes,
});


export default router;
