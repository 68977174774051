// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("src/assets/images/jsc/top_border.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".header{position:absolute;left:0;top:0;width:100%;height:3.625rem;z-index:2;justify-content:space-between;padding:0 3.58333rem 0 3.25rem}.header,.times_s{display:flex;align-items:center;box-sizing:border-box}.times_s{font-size:.91667rem;color:#fff;font-family:ysbth;font-style:normal;line-height:1.20833rem;padding-top:.83333rem}.header-content-bg{position:absolute;top:0;left:0;height:100%;width:100%;height:3.625rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%}.header-title-text{position:absolute;left:50%;transform:translate3d(-50%,-50%,0);margin-top:2.5rem;z-index:2;font-size:1.75rem;font-weight:400;color:#fff;line-height:2.29167rem;letter-spacing:.16667rem;font-family:ysbth;text-shadow:0 0 .41667rem rgba(0,182,205,.8)}@-webkit-keyframes pulsate-fwd{0%{transform:scaleX(1)}30%{transform:scale3d(.8,1.25,1);opacity:1}40%{transform:scaleY(.75);opacity:.5}50%{transform:scale3d(.2,1.15,1);opacity:0}65%{transform:scaleY(.95);opacity:.5}75%{transform:scale3d(.8,1.05,1);opacity:1}to{transform:scaleX(1)}}@keyframes pulsate-fwd{0%{transform:scaleX(1)}30%{transform:scale3d(.8,1.25,1);opacity:1}40%{transform:scaleY(.75);opacity:.5}50%{transform:scale3d(.2,1.15,1);opacity:0}65%{transform:scaleY(.95);opacity:.5}75%{transform:scale3d(.8,1.05,1);opacity:1}to{transform:scaleX(1)}}@-webkit-keyframes pulsates{0%{left:34%}to{left:48%}}@keyframes pulsates{0%{left:34%}to{left:48%}}.margin-l-10{margin-left:.41667rem}.scha{z-index:99}", ""]);
// Exports
module.exports = exports;
